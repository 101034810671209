// ServicesSection.js
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ServicesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="py-20 bg-gradient-to-br from-gray-40 to-gray-100 text-gray-900">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            Bizning <span className="text-yellow-400">Xizmatlarimiz</span>
          </h2>
          <p className="text-gray-600 text-lg">Sizga eng yaxshi xizmat ko'rsatish uchun doimo tayyormiz</p>
        </div>
        
        <div className="max-w-7xl mx-auto">
          <Slider {...settings} className="!-mx-4">
            {/* Xizmat 1 */}
            <div className="px-4 pb-8">
              <div className="group bg-white h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Shahar ichki yo'nalishlarda taksi</h3>
                  <p className="text-gray-600 leading-relaxed">Shahar ichida tez va qulay yetkazib berish xizmati. Professional haydovchilar jamoasi sizning xizmatingizda.</p>
                </div>
              </div>
            </div>

            {/* Xizmat 2 */}
            <div className="px-4 pb-8">
              <div className="group bg-white h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Yetkazib berish (Dostavka)</h3>
                  <p className="text-gray-600 leading-relaxed">Tez va ishonchli yetkazib berish xizmati. Professional haydovchilar jamoasi sizning xizmatingizda.</p>
                </div>
              </div>
            </div>

            {/* Xizmat 3 */}
            <div className="px-4 pb-8">
              <div className="group bg-white h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Maktab xizmati</h3>
                  <p className="text-gray-600 leading-relaxed">Farzandlaringizni maktabga xavfsiz yetkazib berish. Professional haydovchilar jamoasi sizning xizmatingizda.</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <style jsx global>{`
        .slick-dots {
          bottom: -5px;
        }
        .slick-dots li button:before {
          font-size: 12px;
          color: #EAB308;
          opacity: 0.3;
        }
        .slick-dots li.slick-active button:before {
          opacity: 1;
          color: #EAB308;
        }
        .slick-list {
          margin: 0 -1rem;
        }
        .slick-slide > div {
          margin: 0 1rem;
        }
      `}</style>
    </section>
  );
};

export default ServicesSection;
