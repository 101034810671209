import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="py-20 bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            Taksi kerakmi? <span className="text-yellow-400">Bizga ishoning!</span>
          </h2>
          <p className="text-gray-400 text-lg">Professional va ishonchli xizmat</p>
        </div>
        
        <div className="max-w-7xl mx-auto">
          <Slider {...settings} className="!-mx-4">
            {/* Feature 1 - Tezkor */}
            <div className="px-4 pb-8">
              <div className="group bg-gray-800 h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Tezkor xizmat</h3>
                  <p className="text-gray-400 leading-relaxed">5 daqiqa ichida eng yaqin taksi sizning xizmatingizda</p>
                </div>
              </div>
            </div>

            {/* Feature 2 - Ishonchli */}
            <div className="px-4 pb-8">
              <div className="group bg-gray-800 h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Ishonchli haydovchilar</h3>
                  <p className="text-gray-400 leading-relaxed">Tajribali va malakali haydovchilar jamoasi</p>
                </div>
              </div>
            </div>

            {/* Feature 3 - Qulay */}
            <div className="px-4 pb-8">
              <div className="group bg-gray-800 h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Qulay narxlar</h3>
                  <p className="text-gray-400 leading-relaxed">Hamyonbop va raqobatbardosh narxlar</p>
                </div>
              </div>
            </div>

            {/* Feature 4 - 24/7 */}
            <div className="px-4 pb-8">
              <div className="group bg-gray-800 h-[350px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">24/7 Xizmat</h3>
                  <p className="text-gray-400 leading-relaxed">Kunning istalgan vaqtida xizmatingizda</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <style jsx global>{`
        .slick-dots {
          bottom: -5px;
        }
        .slick-dots li button:before {
          font-size: 12px;
          color: #EAB308;
          opacity: 0.3;
        }
        .slick-dots li.slick-active button:before {
          opacity: 1;
          color: #EAB308;
        }
        .slick-list {
          margin: 0 -1rem;
        }
        .slick-slide > div {
          margin: 0 1rem;
        }
      `}</style>
    </section>
  );
};

export default FeaturesSection;
