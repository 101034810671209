import React from 'react';
import googlePlay from '../../assets/google_play.png';
import appScreen1 from '../../assets/app-screen1.jpg';
import appScreen2 from '../../assets/telegram-screen1.jpg';

const AppDownloadSection = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-gray-900 to-black text-white overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold">
              Mobil ilovamizni <span className="text-yellow-400">yuklab oling</span>
            </h2>
            <p className="text-gray-300 text-lg">
              Taksini bir necha soniyada chaqiring va qulay to'lov usullaridan foydalaning
            </p>
            <div className="flex gap-6">
              <a 
                href="https://play.google.com/store/apps/details?id=uz.mysoft.quqonidealtaxi"
                target="_blank"
                src="googlePlay"
                rel="noopener noreferrer"
                className="flex items-center gap-3 px-6 py-3 bg-white text-black rounded-xl hover:bg-gray-100 transition-all duration-300 transform hover:scale-105"
              >
                <img src={googlePlay} alt="Google Play" className="h-8" />
              </a>

              <a 
                href="https://t.me/idealtaxibot"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 px-6 py-3 bg-[#0088cc] text-white rounded-xl hover:bg-[#0077b5] transition-all duration-300 transform hover:scale-105"
              >
                <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.504 1.201-.825 1.23-.703.064-1.237-.435-1.917-.851-1.064-.651-1.669-1.056-2.7-1.667-1.194-.708-.42-1.097.261-1.734.179-.166 3.282-3.001 3.342-3.259.007-.032.014-.154-.059-.218-.073-.064-.175-.042-.249-.024-.105.024-1.775 1.128-5.011 3.315-.474.325-.904.487-1.29.487-.425-.005-1.246-.241-1.854-.439-.752-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.476-1.635.099-.002.321.023.465.141.119.098.152.228.166.349-.001.089-.014.178-.039.265z"/>
                </svg>
                <span className="font-semibold">Telegram</span>
              </a>
            </div>
          </div>

          {/* App Screenshots */}
          <div className="relative">
            {/* Background Glow Effect */}
            <div className="absolute -inset-4 bg-yellow-400 opacity-20 blur-3xl rounded-full"></div>
            
            {/* Screenshots Container */}
            <div className="relative flex justify-center items-center">
              {/* First Screenshot */}
              <div className="transform -rotate-12 translate-x-4 hover:rotate-0 transition-all duration-300">
                <img 
                  src={appScreen1} 
                  alt="App Screenshot 1" 
                  className="w-48 md:w-64 rounded-3xl shadow-2xl border-8 border-black"
                />
              </div>
              
              {/* Second Screenshot */}
              <div className="transform rotate-12 -translate-x-4 hover:rotate-0 transition-all duration-300">
                <img 
                  src={appScreen2} 
                  alt="App Screenshot 2" 
                  className="w-48 md:w-64 rounded-3xl shadow-2xl border-8 border-black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownloadSection;
