import React from 'react';
import { motion } from 'framer-motion';
import dubaiImage from '../../assets/dubai.jpg';

const PromotionSection = () => {
  return (
    <div className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-12 sm:py-20">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-2xl overflow-hidden"
        >
          {/* Hero Image Section */}
          <div className="relative h-[300px] sm:h-[400px]">
            <img 
              src={dubaiImage} 
              alt="Dubai Skyline" 
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="absolute bottom-0 left-0 right-0 text-center p-6"
              >
                <h2 className="text-3xl sm:text-5xl font-bold text-white mb-2">
                  🌟 Dubai Sayohati 🌟
                </h2>
                <p className="text-yellow-400 text-lg sm:text-xl">
                  Imkoniyatni boy bermang!
                </p>
              </motion.div>
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6 sm:p-10">
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="space-y-6 sm:space-y-8"
            >
              <div className="text-center max-w-3xl mx-auto">
                <p className="text-xl sm:text-3xl mb-6 leading-relaxed">
                  Fevral oyi davomida <span className="font-bold">10 marta</span> taksi chaqirgan{' '}
                  <span className="font-bold text-yellow-600">2 nafar omadli mijozimizga</span>
                  <br />
                  <span className="text-2xl sm:text-4xl font-bold bg-gradient-to-r from-yellow-600 to-yellow-800 bg-clip-text text-transparent mt-4 block">
                    Dubaiga Sayohat Sovg'a!
                  </span>
                </p>

                {/* Timer Section */}
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 max-w-2xl mx-auto mb-8">
                  <div className="bg-yellow-50 p-4 rounded-xl">
                    <h3 className="font-bold text-yellow-800 text-2xl sm:text-3xl">28</h3>
                    <p className="text-gray-600">Kun</p>
                  </div>
                  <div className="bg-yellow-50 p-4 rounded-xl">
                    <h3 className="font-bold text-yellow-800 text-2xl sm:text-3xl">02</h3>
                    <p className="text-gray-600">Soat</p>
                  </div>
                  <div className="bg-yellow-50 p-4 rounded-xl">
                    <h3 className="font-bold text-yellow-800 text-2xl sm:text-3xl">24</h3>
                    <p className="text-gray-600">Daqiqa</p>
                  </div>
                  <div className="bg-yellow-50 p-4 rounded-xl">
                    <h3 className="font-bold text-yellow-800 text-2xl sm:text-3xl">60</h3>
                    <p className="text-gray-600">Soniya</p>
                  </div>
                </div>

                {/* Results Announcement */}
                <div className="bg-gray-50 p-6 rounded-xl border border-yellow-200">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                    G'oliblar E'loni
                  </h3>
                  <p className="text-gray-600">
                    Natijalar <span className="font-bold text-yellow-600">1-mart soat 12:00 da</span>{' '}
                    <a 
                      href="https://instagram.com/idealtaxi.kokand" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 underline"
                    >
                      @idealtaxi.kokand
                    </a> instagram sahifasida e'lon qilinadi
                  </p>
                </div>

                <div className="mt-6 text-gray-500 text-sm space-y-1">
                  <p>* Aksiya shartlari amal qiladi</p>
                  <p>* Fevral oyi oxirigacha amal qiladi</p>
                  <p>* G'oliblar mart oyi boshida e'lon qilinadi</p>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PromotionSection;
