import React, { useState } from 'react';
import { FaPhone } from 'react-icons/fa';
import API_BASE_URL from '../../config/config';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.message) {
      alert('Xabar maydoni bo\'sh bo\'lmasligi kerak!');
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/feedback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Feedback submitted successfully!');
        setFormData({ name: '', phone: '', message: '' });
      } else {
        alert('Failed to submit feedback.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting feedback.');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-gray-900 to-black text-white py-12 sm:py-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute -top-24 -right-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
          <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6">
              Biz bilan <span className="text-yellow-400">bog'laning</span>
            </h1>
            <p className="text-lg sm:text-xl text-gray-400 leading-relaxed">
              24/7 sizning xizmatingizda. Biz bilan bog'lanish uchun qulay usulni tanlang.
            </p>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="py-8 sm:py-16">
        <div className="container mx-auto px-4">
          {/* Contact Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 mb-8 sm:mb-16">
            {/* Phone Card */}
            <div className="bg-white p-6 sm:p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 group">
              <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold mb-4">Telefon raqamlar</h3>
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-yellow-400" />
                  <p className="text-gray-600">+998 90 305 07 00</p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-yellow-400" />
                  <p className="text-gray-600">+998 95 400 07 00</p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-yellow-400" />
                  <p className="text-gray-600">1053</p>
                </div>
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-yellow-400" />
                  <p className="text-gray-600">0070 (Beline)</p>
                </div>
              </div>
            </div>

            {/* Telegram Card */}
            <div className="bg-white p-6 sm:p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 group">
              <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                <svg className="w-8 h-8 text-black" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.504 1.201-.825 1.23-.703.064-1.237-.435-1.917-.851-1.064-.651-1.669-1.056-2.7-1.667-1.194-.708-.42-1.097.261-1.734.179-.166 3.282-3.001 3.342-3.259.007-.032.014-.153-.056-.217-.07-.064-.171-.042-.244-.025-.103.025-1.745 1.115-4.928 3.272-.466.32-.89.476-1.271.468-.418-.009-1.223-.241-1.82-.44-.734-.244-1.316-.374-1.266-.788.026-.217.273-.437.739-.661 2.89-1.261 4.823-2.094 5.797-2.499.275-.114 2.75-1.142 2.75-1.142.685-.287 1.304-.425 1.577-.425.061 0 .185.004.271.022.364.076.456.273.481.387.017.075.026.21.017.294z"/>
                </svg>
              </div>
              <h3 className="text-2xl font-bold mb-4">Telegram</h3>
              <div className="space-y-2">
                <a href="https://t.me/idealtaxibot" className="text-gray-600 hover:text-yellow-400 transition duration-300">@idealtaxibot</a>
              </div>
            </div>

            {/* Location Card */}
            <div className="bg-white p-6 sm:p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 group">
              <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold mb-4">Manzil</h3>
              <p className="text-gray-600">Charxiy Mavzesi, Kokand, Fergana ili, O'zbekistan</p>
            </div>
          </div>

          {/* Contact Form */}
          <div className="max-w-3xl mx-auto bg-white rounded-3xl shadow-lg p-6 sm:p-8 mb-8 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4">Fikr va mulohazalar</h2>
            <p className="text-gray-600 mb-6 sm:mb-8 text-sm sm:text-base">
              Bizning xizmatlarimiz haqida fikringizni bildiring yoki taklif va shikoyatlaringizni yozing
            </p>
            <form className="space-y-4 sm:space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                <div>
                  <label className="block text-gray-700 font-medium mb-2">Ismingiz</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-xl border border-gray-300 focus:border-yellow-400 focus:ring-2 focus:ring-yellow-200 outline-none transition duration-300"
                    placeholder="Ismingizni kiriting"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2">Telefon</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-xl border border-gray-300 focus:border-yellow-400 focus:ring-2 focus:ring-yellow-200 outline-none transition duration-300"
                    placeholder="+998"
                  />
                </div>
              </div>
              <div>
                <label className="block text-gray-700 font-medium mb-2">Xabar</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-xl border border-gray-300 focus:border-yellow-400 focus:ring-2 focus:ring-yellow-200 outline-none transition duration-300 h-32"
                  placeholder="Xabaringizni yozing"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-yellow-400 text-black font-semibold px-8 py-4 rounded-xl hover:bg-yellow-500 transition duration-300"
              >
                Yuborish
              </button>
            </form>
          </div>

          {/* Map Section - Yeniden Düzenlenmiş */}
          <div className="relative bg-white rounded-3xl shadow-lg overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent z-10 h-24 pointer-events-none"></div>
            <div className="absolute top-6 left-6 z-20 bg-yellow-400 backdrop-blur-sm px-6 py-4 rounded-2xl shadow-lg">
              <h3 className="text-lg font-bold mb-2">Bizning Manzil</h3>
            </div>
            <div className="h-[500px] w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3030.9430894024945!2d70.925572!3d40.564934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDMzJzUzLjgiTiA3MMKwNTUnMzIuMSJF!5e0!3m2!1str!2str!4v1735420738758!5m2!1str!2str"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                className="w-full h-full"
                title="Qo'qon Ideal Taxi Location"
              ></iframe>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;
