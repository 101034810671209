import React, { useState, useEffect } from 'react';
import { FaClock, FaCar, FaSchool, FaBox, FaUsers, FaStar, FaMoneyBillWave } from 'react-icons/fa';
import useCounter from '../../hooks/useCounter';

const ServicesPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const drivers = useCounter(isVisible ? 1500 : 0);
  const clients = useCounter(isVisible ? 25000 : 0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById('stats-grid');
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="bg-gradient-to-br from-gray-900 via-gray-800 to-black text-white py-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 right-0 w-96 h-96 bg-yellow-400 rounded-full opacity-10 blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-yellow-400 rounded-full opacity-10 blur-3xl"></div>
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Bizning <span className="text-yellow-400">Xizmatlarimiz</span>
            </h1>
            <p className="text-xl text-gray-400 leading-relaxed">
              Professional va ishonchli taksi xizmati. Shahar ichida va tashqarisida xavfsiz va qulay sayohat uchun biz doimo tayyormiz.
            </p>
          </div>
        </div>
      </header>

      {/* Services Section */}
      <main className="py-16">
        <div className="container mx-auto px-4">
          {/* Main Services */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 mb-20">
            {/* Shahar Taxi */}
            <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
              <div className="relative z-10 h-full flex flex-col">
                <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                  <FaCar className="w-8 h-8 text-black" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Shahar Taxi</h3>
                <p className="text-gray-600">Shahar ichida tez va qulay yetkazib berish xizmati.</p>
              </div>
            </div>

            {/* Yetkazib berish */}
            <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
              <div className="relative z-10 h-full flex flex-col">
                <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                  <FaBox className="w-8 h-8 text-black" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Yetkazib berish</h3>
                <p className="text-gray-600">Tez va ishonchli yetkazib berish xizmati.</p>
              </div>
            </div>

            {/* Maktab xizmati */}
            <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
              <div className="relative z-10 h-full flex flex-col">
                <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                  <FaSchool className="w-8 h-8 text-black" />
                </div>
                <h3 className="text-2xl font-bold mb-4">Maktab xizmati</h3>
                <p className="text-gray-600">Farzandlaringizni maktabga xavfsiz yetkazib berish.</p>
              </div>
            </div>
          </div>

          {/* Stats Section */}
          <div id="stats-grid" className="bg-gradient-to-br from-gray-900 to-black text-white rounded-3xl p-12 mb-20">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div className="text-center">
                <FaUsers className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <div className="text-3xl font-bold mb-2">{drivers}+</div>
                <div className="text-gray-400">Haydovchilar</div>
              </div>
              <div className="text-center">
                <FaStar className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <div className="text-3xl font-bold mb-2">{clients}+</div>
                <div className="text-gray-400">Mijozlar</div>
              </div>
              <div className="text-center">
                <FaClock className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <div className="text-3xl font-bold mb-2">24/7</div>
                <div className="text-gray-400">Xizmat</div>
              </div>
              <div className="text-center">
                <FaMoneyBillWave className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <div className="text-3xl font-bold mb-2">Qulay</div>
                <div className="text-gray-400">Narxlar</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ServicesPage;
