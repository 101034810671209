import React, { useState, useEffect } from 'react';
import { FaClock, FaCar, FaUsers, FaAward, FaShieldAlt, FaStar } from 'react-icons/fa';
import Slider from 'react-slick';
import useCounter from '../../hooks/useCounter';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aboutImage from '../../assets/image1.png';

const AboutPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const experienceYears = useCounter(isVisible ? 14 : 0);
  const driversCount = useCounter(isVisible ? 2500 : 0);
  const clientsCount = useCounter(isVisible ? 25000 : 0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById('stats-section');
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-gray-900 to-black text-white py-12 sm:py-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute -top-24 -right-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
          <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
              Biz <span className="text-yellow-400">haqimizda</span>
            </h1>
            <p className="text-xl text-gray-400 leading-relaxed">
              14 yillik tajriba va professional xizmat
            </p>
          </div>
        </div>
      </header>

      <main className="py-8 sm:py-16">
        <div className="container mx-auto px-4">
          {/* Main Content */}
          <div id="stats-section" className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-12 items-center mb-12 sm:mb-20">
            <div className="relative group">
              <div className="aspect-square rounded-3xl overflow-hidden shadow-2xl transform group-hover:scale-[1.02] transition-all duration-500">
                <img 
                  src={aboutImage} 
                  alt="Ideal Taxi" 
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
              </div>
              <div className="absolute -bottom-4 sm:-bottom-8 -right-4 sm:-right-8 bg-yellow-400 rounded-3xl p-4 sm:p-8 shadow-xl transform group-hover:translate-x-2 group-hover:translate-y-2 transition-all duration-500">
                <div className="text-3xl sm:text-5xl font-bold">{experienceYears}+</div>
                <div className="text-gray-800 font-semibold text-sm sm:text-base">Yillik tajriba</div>
              </div>
            </div>
            
            <div className="space-y-6 sm:space-y-8">
              <div className="space-y-3 sm:space-y-4">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold">
                  "Qo'qon <span className="text-yellow-400">Ideal Taxi</span>" kompaniyasi
                </h2>
                <p className="text-gray-600 text-base sm:text-lg leading-relaxed">
                  Shahrimiz aholisiga 2009 yildan buyon xizmat ko'rsatib kelmoqda. Bu yillar davomida biz mijozlarimizning ishonchini qozonib, shahar transporti sohasida yetakchi kompaniyalardan biriga aylandik.
                </p>
              </div>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <div className="bg-white p-6 sm:p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <FaUsers className="w-6 h-6 sm:w-8 sm:h-8 text-yellow-400 mb-4" />
                  <div className="text-3xl sm:text-4xl font-bold text-yellow-400">{driversCount}+</div>
                  <div className="text-gray-600 font-medium text-sm sm:text-base">Professional haydovchilar</div>
                </div>
                <div className="bg-white p-6 sm:p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <FaStar className="w-6 h-6 sm:w-8 sm:h-8 text-yellow-400 mb-4" />
                  <div className="text-3xl sm:text-4xl font-bold text-yellow-400">{clientsCount}+</div>
                  <div className="text-gray-600 font-medium text-sm sm:text-base">Mamnun mijozlar (Kunlik)</div>
                </div>
              </div>
            </div>
          </div>

          {/* Features Grid - Slider olarak güncellendi */}
          <div className="mb-12 sm:mb-20">
            <div className="max-w-7xl mx-auto">
              <Slider {...settings} className="!-mx-4">
                <div className="px-4">
                  <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col justify-between relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                    <div>
                      <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                        <FaClock className="w-8 h-8 text-black" />
                      </div>
                      <h3 className="text-xl font-bold mb-4">24/7 Xizmat</h3>
                      <p className="text-gray-600">Kunning istalgan vaqtida xizmatingizda</p>
                    </div>
                  </div>
                </div>

                <div className="px-4">
                  <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col justify-between relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                    <div>
                      <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                        <FaShieldAlt className="w-8 h-8 text-black" />
                      </div>
                      <h3 className="text-xl font-bold mb-4">Xavfsizlik</h3>
                      <p className="text-gray-600">Ishonchli va xavfsiz sayohat</p>
                    </div>
                  </div>
                </div>

                <div className="px-4">
                  <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col justify-between relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                    <div>
                      <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                        <FaCar className="w-8 h-8 text-black" />
                      </div>
                      <h3 className="text-xl font-bold mb-4">Zamonaviy Avtomobillar</h3>
                      <p className="text-gray-600">Qulay va zamonaviy avtomobillar</p>
                    </div>
                  </div>
                </div>

                <div className="px-4">
                  <div className="group bg-white h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col justify-between relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                    <div>
                      <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
                        <FaAward className="w-8 h-8 text-black" />
                      </div>
                      <h3 className="text-xl font-bold mb-4">Professional Xizmat</h3>
                      <p className="text-gray-600">Malakali haydovchilar jamoasi</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <style jsx global>{`
              .slick-dots {
                bottom: -35px;
              }
              .slick-dots li button:before {
                font-size: 12px;
                color: #EAB308;
                opacity: 0.3;
              }
              .slick-dots li.slick-active button:before {
                opacity: 1;
                color: #EAB308;
              }
              .slick-list {
                margin: 0 -1rem;
                padding: 1rem 0;
              }
              .slick-slide > div {
                margin: 0 1rem;
              }
            `}</style>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AboutPage;
