import React, { useEffect, useState } from 'react';
import useCounter from '../../hooks/useCounter';

const StatsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const drivers = useCounter(isVisible ? 1500 : 0);
  const clients = useCounter(isVisible ? 25000 : 0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById('stats-section');
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <section id="stats-section" className="py-20 bg-gradient-to-br from-gray-900 to-gray-800 text-white relative overflow-hidden">
      {/* Dekoratif arka plan elementleri */}
      <div className="absolute inset-0">
        <div className="absolute -top-24 -right-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
        <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-yellow-400 rounded-full opacity-20 blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            Bizning <span className="text-yellow-400">Statistikamiz</span>
          </h2>
          <p className="text-gray-400 text-lg">Raqamlarda biz</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {/* Haydovchilar */}
          <div className="group bg-gray-800/50 p-8 rounded-3xl hover:bg-gray-800 transition-all duration-300 backdrop-blur-sm border border-gray-700/50">
            <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
              <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div className="text-4xl md:text-3xl font-bold mb-2 group-hover:text-yellow-400 transition-colors">
              {drivers}+
            </div>
            <div className="text-gray-400 text-lg">Haydovchilar</div>
          </div>

          {/* Mijozlar */}
          <div className="group bg-gray-800/50 p-8 rounded-3xl hover:bg-gray-800 transition-all duration-300 backdrop-blur-sm border border-gray-700/50">
            <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
              <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
            <div className="text-4xl md:text-3xl font-bold mb-2 group-hover:text-yellow-400 transition-colors">
              {clients}+ (Kunlik)
            </div>
            <div className="text-gray-400 text-lg">Mijozlar</div>
          </div>

          {/* Xizmat */}
          <div className="group bg-gray-800/50 p-8 rounded-3xl hover:bg-gray-800 transition-all duration-300 backdrop-blur-sm border border-gray-700/50">
            <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-6 group-hover:rotate-12 transition-transform duration-300">
              <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="text-4xl md:text-3xl font-bold mb-2 group-hover:text-yellow-400 transition-colors">24/7</div>
            <div className="text-gray-400 text-lg">Xizmat</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
