import React from 'react';
import taxiImage from '../../assets/taxi.png';
import googlePlay from '../../assets/google_play.png';

const HeroSection = ({ showModal, setShowModal }) => {
  return (
    <section className="relative min-h-screen bg-gradient-to-br from-black via-gray-900 to-yellow-900">
    {/* Animated Background Overlay */}
    <div className="absolute inset-0 opacity-20">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml,...')] bg-repeat opacity-10 animate-slide"></div>
    </div>

    <div className="container mx-auto px-4 min-h-screen flex items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 items-center">
        {/* Left Content */}
        <div className="text-white space-y-6 md:space-y-8 animate-fade-in text-center lg:text-left">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
            Ishonchli va Tez 
            <span className="block mt-2">
              <span className="text-yellow-400">Taksi</span> Xizmati
            </span>
          </h1>
          
          <p className="text-gray-300 text-base sm:text-lg md:text-xl max-w-xl mx-auto lg:mx-0">
            Shahar ichki yo'nalishlarda va shaharlararo qatnov uchun 
            <span className="text-yellow-400 font-semibold"> eng yaxshi tanlov</span>
          </p>

          <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
            <button 
              onClick={() => setShowModal(true)}
              className="group relative px-6 sm:px-8 py-3 sm:py-4 bg-yellow-400 text-black font-bold rounded-full overflow-hidden transition-all duration-300 hover:bg-yellow-300 text-sm sm:text-base"
            >
              <span className="relative z-10">BUYURTMA BERING</span>
              <div className="absolute inset-0 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300 opacity-20"></div>
            </button>
          </div>
        </div>

        {/* Right Image */}
        <div className="relative mt-8 lg:mt-0 px-4 sm:px-8 md:px-12 lg:px-0">
          <div className="absolute -inset-4 bg-yellow-400 rounded-full opacity-20 blur-3xl animate-pulse"></div>
          <img 
            src={taxiImage} 
            alt="Taxi Service" 
            className="relative w-full h-auto max-w-sm sm:max-w-md md:max-w-lg mx-auto transform hover:scale-105 transition-transform duration-500 drop-shadow-2xl" 
          />
        </div>
      </div>
    </div>

    {/* Modal - Responsive ayarları */}
    {showModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-2xl p-4 sm:p-6 md:p-8 w-full max-w-md mx-4 transform transition-all">
          <div className="flex justify-between items-center mb-4 sm:mb-6">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">Buyurtma berish usulini tanlang</h3>
            <button 
              onClick={() => setShowModal(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="space-y-4">
            <a 
              href="https://play.google.com/store/apps/details?id=uz.mysoft.quqonidealtaxi"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between p-4 bg-black text-white rounded-xl hover:bg-gray-900 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <img src={googlePlay} alt="Google Play" className="w-8 h-8" />
                <span className="font-semibold">Mobil ilovasi orqali (Android)</span>
              </div>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </a>

            <a 
              href="https://t.me/idealtaxibot"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between p-4 bg-[#0088cc] text-white rounded-xl hover:bg-[#0077b5] transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.504 1.201-.825 1.23-.703.064-1.237-.435-1.917-.851-1.064-.651-1.669-1.056-2.7-1.667-1.194-.708-.42-1.097.261-1.734.179-.166 3.282-3.001 3.342-3.259.007-.032.014-.154-.059-.218-.073-.064-.175-.042-.249-.024-.105.024-1.775 1.128-5.011 3.315-.474.325-.904.487-1.29.487-.425-.005-1.246-.241-1.854-.439-.752-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.476-1.635.099-.002.321.023.465.141.119.098.152.228.166.349-.001.089-.014.178-.039.265z"/>
                </svg>
                <span className="font-semibold">Telegram Bot orqali</span>
              </div>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    )}

    {/* Scroll Indicator */}
    <div className="absolute bottom-4 sm:bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
      <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </div>
  </section>
  );
};

export default HeroSection;
