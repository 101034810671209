import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPhone } from 'react-icons/fa';

const ContactSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="py-20 bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            Biz bilan <span className="text-yellow-400">bog'laning</span>
          </h2>
          <p className="text-gray-400 text-lg">Biz bilan bog'lanish uchun quyidagi usullardan foydalaning</p>
        </div>
        
        <div className="max-w-7xl mx-auto">
          <Slider {...settings} className="!-mx-4">
            {/* Telefon */}
            <div className="px-4 pb-8">
              <div className="group bg-gray-800 h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                <div>
                  <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                    <svg className="w-8 h-8 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Telefon</h3>
                    <div className="flex items-center space-x-2">
                        <FaPhone className="text-yellow-400" />
                        <p className="text-gray-400 leading-relaxed">+998 90 305 07 00</p>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FaPhone className="text-yellow-400" />
                        <p className="text-gray-400 leading-relaxed">+998 95 400 07 00</p>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FaPhone className="text-yellow-400" />
                        <p className="text-gray-400 leading-relaxed">1053</p>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FaPhone className="text-yellow-400" />
                        <p className="text-gray-400 leading-relaxed">0070 (Beline)</p>
                    </div>
                </div>
              </div>
            </div>

            {/* Telegram */}
            <div className="px-4 pb-8">
              <a href="https://t.me/idealtaxibot" target="_blank" rel="noopener noreferrer">
                <div className="group bg-gray-800 h-[300px] p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-500 border-2 border-transparent hover:border-yellow-400 flex flex-col justify-between relative overflow-hidden">
                  <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-400 opacity-10 rounded-full -mr-16 -mt-16 group-hover:scale-150 transition-transform duration-500"></div>
                  <div>
                    <div className="w-16 h-16 bg-yellow-400 rounded-2xl flex items-center justify-center mb-8 group-hover:rotate-12 transition-transform duration-300">
                      <svg className="w-8 h-8 text-black" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.504 1.201-.825 1.23-.703.064-1.237-.435-1.917-.851-1.064-.651-1.669-1.056-2.7-1.667-1.194-.708-.42-1.097.261-1.734.179-.166 3.282-3.001 3.342-3.259.007-.032.014-.154-.059-.218-.073-.064-.175-.042-.249-.024-.105.024-1.775 1.128-5.011 3.315-.474.325-.904.487-1.29.487-.425-.005-1.246-.241-1.854-.439-.752-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.476-1.635.099-.002.321.023.465.141.119.098.152.228.166.349-.001.089-.014.178-.039.265z"/>
                      </svg>
                    </div>
                    <h3 className="text-2xl font-bold mb-4">Telegram</h3>
                    <p className="text-gray-400 leading-relaxed">@idealtaxibot</p>
                  </div>
                </div>
              </a>
            </div>
          </Slider>
        </div>
      </div>

      <style jsx global>{`
        .slick-dots {
          bottom: -5px;
        }
        .slick-dots li button:before {
          font-size: 12px;
          color: #EAB308;
          opacity: 0.3;
        }
        .slick-dots li.slick-active button:before {
          opacity: 1;
          color: #EAB308;
        }
        .slick-list {
          margin: 0 -1rem;
        }
        .slick-slide > div {
          margin: 0 1rem;
        }
      `}</style>
    </section>
  );
};

export default ContactSection;
