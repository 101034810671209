import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo500.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-gradient-to-r from-gray-900 to-black text-white py-4 shadow-xl sticky top-0 z-50 backdrop-blur-lg bg-opacity-90">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <Link to="/" className="flex items-center group">
          <img src={logo} alt="TaxiCo Logo" className="h-10 group-hover:scale-105 transition-transform duration-300" />
        </Link>
        
        {/* Mobil Menü Butonu */}
        <button 
          className="md:hidden bg-gray-800 p-2 rounded-lg hover:bg-gray-700 transition-colors duration-300"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            )}
          </svg>
        </button>

        {/* Desktop Menü */}
        <nav className="hidden md:flex items-center space-x-8">
          <Link to="/" className="relative group">
            <span className="text-base font-medium hover:text-yellow-400 transition duration-300">Bosh Sahifa</span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-yellow-400 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/services" className="relative group">
            <span className="text-base font-medium hover:text-yellow-400 transition duration-300">Xizmatlar</span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-yellow-400 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/contact" className="relative group">
            <span className="text-base font-medium hover:text-yellow-400 transition duration-300">Aloqa</span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-yellow-400 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/about" className="relative group">
            <span className="text-base font-medium hover:text-yellow-400 transition duration-300">Haqida</span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-yellow-400 group-hover:w-full transition-all duration-300"></span>
          </Link>
        </nav>
      </div>

      {/* Mobil Menü */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden absolute w-full bg-gray-900 border-t border-gray-800`}>
        <nav className="container mx-auto px-4 py-4 space-y-4">
          <Link to="/" className="block text-base hover:text-yellow-400 transition duration-300">Bosh Sahifa</Link>
          <Link to="/services" className="block text-base hover:text-yellow-400 transition duration-300">Xizmatlar</Link>
          <Link to="/contact" className="block text-base hover:text-yellow-400 transition duration-300">Aloqa</Link>
          <Link to="/about" className="block text-base hover:text-yellow-400 transition duration-300">Haqida</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
