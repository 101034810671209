import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTelegram, FaInstagram, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo500.png';

const Footer = () => {
  const [showSocial, setShowSocial] = useState(false);

  return (
    <footer className="bg-gradient-to-br from-gray-900 to-black text-white pt-16 pb-8">
      {/* Sosyal Medya Popup */}
      {showSocial && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-8 max-w-md w-full mx-4 relative">
            <button 
              onClick={() => setShowSocial(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <FaTimes className="w-6 h-6" />
            </button>
            
            <h3 className="text-2xl font-bold text-gray-800 mb-6">Ijtimoiy Tarmoqlar</h3>
            
            <div className="space-y-4">
              <a 
                href="https://www.linkedin.com/in/abdullah-bahromov-79b84621a/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center space-x-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <FaLinkedin className="w-8 h-8 text-blue-600" />
                <span className="text-gray-700 font-medium">LinkedIn</span>
              </a>
              
              <a 
                href="https://t.me/abdullahbhrmv" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center space-x-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <FaTelegram className="w-8 h-8 text-blue-500" />
                <span className="text-gray-700 font-medium">Telegram</span>
              </a>
              
              <a 
                href="https://www.instagram.com/abdullahbhrmv/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center space-x-4 p-4 rounded-2xl bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <FaInstagram className="w-8 h-8 text-pink-600" />
                <span className="text-gray-700 font-medium">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      )}

      {/* Footer Content */}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo va Ma'lumot */}
          <div className="space-y-6">
            <Link to="/" className="flex items-center group">
              <img src={logo} alt="TaxiCo Logo" className="h-10 group-hover:scale-105 transition-transform duration-300" />
            </Link>
            <p className="text-gray-400 text-sm">Professional va ishonchli taksi xizmati. 24/7 sizning xizmatingizda.</p>
          </div>

          {/* Tezkor Havolalar */}
          <div className="space-y-6">
            <h3 className="text-lg font-bold">Tezkor Havolalar</h3>
            <nav className="flex flex-col space-y-3">
              <Link to="/" className="text-gray-400 hover:text-yellow-400 transition duration-300">Bosh Sahifa</Link>
              <Link to="/services" className="text-gray-400 hover:text-yellow-400 transition duration-300">Xizmatlar</Link>
              <Link to="/about" className="text-gray-400 hover:text-yellow-400 transition duration-300">Biz Haqimizda</Link>
              <Link to="/contact" className="text-gray-400 hover:text-yellow-400 transition duration-300">Aloqa</Link>
            </nav>
          </div>

          {/* Xizmatlar */}
          <div className="space-y-6">
            <h3 className="text-lg font-bold">Xizmatlar</h3>
            <nav className="flex flex-col space-y-3">
              <Link to="/services" className="text-gray-400 hover:text-yellow-400 transition duration-300">Shahar ichki yo'nalishlar</Link>
              <Link to="/services" className="text-gray-400 hover:text-yellow-400 transition duration-300">Yetkazib berish</Link>
              <Link to="/services" className="text-gray-400 hover:text-yellow-400 transition duration-300">Maktab xizmati</Link>
            </nav>
          </div>

          {/* Aloqa */}
          <div className="space-y-6">
            <h3 className="text-lg font-bold">Aloqa</h3>
            <div className="space-y-4">
              <a href="tel:+998903050700" className="flex items-center space-x-3 text-gray-400 hover:text-yellow-400 transition duration-300">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <span>+998 90 305 07 00</span>
              </a>
              <a href="tel:+998954000700" className="flex items-center space-x-3 text-gray-400 hover:text-yellow-400 transition duration-300">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <span>+998 95 400 07 00</span>
              </a>
              <a href="https://t.me/idealtaxibot" className="flex items-center space-x-3 text-gray-400 hover:text-yellow-400 transition duration-300">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.504 1.201-.825 1.23-.703.064-1.237-.435-1.917-.851-1.064-.651-1.669-1.056-2.7-1.667-1.194-.708-.42-1.097.261-1.734.179-.166 3.282-3.001 3.342-3.259.007-.032.014-.154-.059-.218-.073-.064-.175-.042-.249-.024-.105.024-1.775 1.128-5.011 3.315-.474.325-.904.487-1.29.487-.425-.005-1.246-.241-1.854-.439-.752-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.476-1.635.099-.002.321.023.465.141.119.098.152.228.166.349-.001.089-.014.178-.039.265z"/>
                </svg>
                <span>@idealtaxibot</span>
              </a>
              <a href="https://www.instagram.com/idealtaxi.kokand" className="flex items-center space-x-3 text-gray-400 hover:text-yellow-400 transition duration-300">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                </svg>
                <span>@idealtaxi.kokand</span>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-sm text-gray-400">
          <p>
            &copy; {new Date().getFullYear()}{' '}
            <button 
              onClick={() => setShowSocial(true)}
              className="hover:text-yellow-400 transition duration-300 font-medium"
            >
              Abdulloh Bahromov
            </button>
            . Barcha huquqlar himoyalangan.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;