import React, { useState } from 'react';
import HeroSection from '../../components/home/HeroSection';
import FeaturesSection from '../../components/home/FeaturesSection';
import ServicesSection from '../../components/home/ServicesSection';
import StatsSection from '../../components/home/StatsSection';
import AppDownloadSection from '../../components/home/AppDownloadSection';
import ContactSection from '../../components/home/ContactSection';
import PromotionSection from '../../components/home/PromotionSection';

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="overflow-hidden">
      {/* Hero Section */}
      <HeroSection showModal={showModal} setShowModal={setShowModal} />
      {/* Promotion Section */}
      <PromotionSection />
      {/* Features Section */}
      <FeaturesSection />
      {/* Services Section */}
      <ServicesSection />
      {/* Stats Section */}
      <StatsSection />
      {/* App Download Section */}
      <AppDownloadSection />
      {/* Contact Section */}
      <ContactSection />
    </div>
  );
};

export default HomePage;
